/* eslint-disable camelcase,no-console,no-undef */
import { toast } from 'react-toastify';

import { http } from '../../http';
import * as actionTypes from '../actionTypes';
import { LoginApiUrl, LogoutUrl, ResetPasswordAPI, ForgetPasswordAPI, ContactUs, PricingEnterprise, Newsletter, SsoCallback } from '../../constants/apiConstants';
import { TimeZone } from '../../../utils/constants';
import { ssoCompanyLogin } from '../registerActions';

const loginRequest = (email) => (dispatch) => dispatch({ type: actionTypes.LOGIN_ATTEMPT, email });

const loginError = (error) => (dispatch) => dispatch({ type: actionTypes.LOGIN_FAILED, error });

const loginSuccess = (response) => (dispatch) => dispatch({ type: actionTypes.LOGIN_SUCCESSFULLY, response });

const authenticatedUser = () => (dispatch) => dispatch({ type: actionTypes.AUTHENTICATED_USER, token: localStorage ? localStorage.getItem('AUTH_TOKEN'): 0 });

const setAdminUser = (isAdmin) => (dispatch) => dispatch({type: actionTypes.SET_ADMIN_USER, isAdmin: isAdmin});

const setUserRole = (role) => (dispatch) => dispatch({type: actionTypes.SET_USER_ROLE, role});

const getUserRole = () => (dispatch) => dispatch({type: actionTypes.GET_USER_ROLE});

const getAdminUser = () => (dispatch) => dispatch({type: actionTypes.GET_ADMIN_USER});

const networkError = (error) => (dispatch) => dispatch({type: actionTypes.NETWORK_ERROR, networkError: error});

const authenticatedUserEmailToken = (token) => (dispatch) => dispatch({ type: actionTypes.AUTHENTICATED_USER_EMAIL_TOKEN, userEmailToken: token });

const login = ({ emailOrUsername, loginPassword }, history, changeSubmit) =>
  async (dispatch) => {
    dispatch(loginRequest(emailOrUsername));
    try {
      let validTimeZone = ''
      validTimeZone = TimeZone === 'Asia/Calcutta' ? 'Asia/Kolkata' : TimeZone;
      const res = await http.post(LoginApiUrl, { email: emailOrUsername, password: loginPassword, device_token: '', 
        deviceOs: '' , time_zone: validTimeZone});
      if(res.message) {
        dispatch(loginError(res.message));
        changeSubmit('user');
      } else {
        localStorage.setItem('AUTH_TOKEN', res.checksum);
        dispatch(checkAndUpdateRole(res));
        dispatch(authenticatedUser());
        dispatch(loginSuccess(res));
        window.location.replace('/portal');
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  };

const ssoLoading = (loading) => (dispatch) => dispatch({type: actionTypes.SSO_LOADING,isLoading:loading});

const loginwithsso = (authcode,history) =>
  async (dispatch) => {
    dispatch(ssoLoading(true));
    // dispatch(loginRequest(emailOrUsername));
    try {
      const res = await http.get(SsoCallback + authcode);
      console.log("loginwithsso",res);
      if(res.message) {
        dispatch(loginError(res?.message));
        dispatch(ssoLoading(false));
        // changeSubmit('user');
      } else {
        if(res.checksum){
          localStorage.setItem('AUTH_TOKEN', res?.checksum);
          dispatch(checkAndUpdateRole(res));
          dispatch(authenticatedUser());
          dispatch(loginSuccess(res));
          history.push('/portal');
          dispatch(ssoLoading(false));

        }else if(res.url){
          // window.console.log("loginwithsso history",history);
          dispatch(ssoCompanyLogin(res,history));
          dispatch(ssoLoading(false));
          // dispatch(checkAndUpdateRole(res));
        }

      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      dispatch(ssoLoading(false));
      // window.location.reload();
    }
  };

const checkAndUpdateRole = (res) => (dispatch) => {
  let isAdmin = false;
  let isWellnessChampion = false;
  let isWellnessAdmin = false;
  let isContentCreator = false;
  res.roles.map((role) => {
    if(role.rid === 3) {
      isAdmin = true
    } else if(role.rid === 11) {
      isWellnessChampion = true;
    } else if(role.rid === 12) {
      isWellnessAdmin = true;
    } else if(role.rid === 13) {
      isContentCreator = true;
    }
  });
  localStorage.setItem('ADMIN_USER', isAdmin);
  localStorage.setItem('WELLNESS_CHAMPION', isWellnessChampion);
  localStorage.setItem('WELLNESS_ADMIN', isWellnessAdmin);
  localStorage.setItem('CONTENT_CREATOR', isContentCreator);
  const role = isAdmin ? 'ADMIN' : isContentCreator ? 'CONTENT_CREATOR' : isWellnessAdmin ? 'WELLNESS_ADMIN' : isWellnessChampion ? 'WELLNESS_CHAMPION' : 'USER';
  dispatch(setUserRole(role));
  dispatch(setAdminUser(isAdmin));
}

const logout = (history) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  const config = {
    headers: {
      AUTHTOKEN: AuthToken
    }
  };
  localStorage.clear();
  return async (dispatch) => {
    try {
      const res = await http.post(LogoutUrl, {}, config);
      if(res.message) {
        dispatch({ type: actionTypes.USER_LOGOUT_FAILED, message: res.message});
      } else {
        dispatch({ type: actionTypes.USER_LOGOUT, message: res[0]});
        dispatch(authenticatedUser());
        history.push('/user');
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const forgetPassword = (data) => (
  async () => {
    try {
      const res = await http.post(ForgetPasswordAPI, {email: data.email});
      if(res[0]) {
        toast.success(res[0])
      }
      else {
        toast.error(res.message)
      }
    }
    catch(error) {
      toast.error(error.message);
    }
  }
);

const resetPassword = (data) => (
  async () => {
    try {
      const res = await http.post(ResetPasswordAPI, {...data});
      if(res.message) {
        toast.error(res.message);
      } else {
        toast.success(res[0]);
      }
    }
    catch(error) {
      toast.error(error.message)
    }
  }
);

const saveContactUsInfo = (name, value) => (dispatch) => dispatch({type: actionTypes.SAVE_CONTACT_US_INFO, name: name, value: value});

const isLoading = () => (dispatch) => dispatch({type: actionTypes.CONTACT_LOADING});

const submitContactForm = (history) => async (dispatch, getState) => {
  const data = {
    fname: _.get(getState().auth.contactUsInfo, 'firstName', ''),
    lname: _.get(getState().auth.contactUsInfo, 'lastName', null),
    email: _.get(getState().auth.contactUsInfo, 'email', ''),
    phone: _.get(getState().auth.contactUsInfo, 'phone', ''),
    company: _.get(getState().auth.contactUsInfo, 'companyName', ''),
    title: _.get(getState().auth.contactUsInfo, 'title', ''),
    industries: _.get(getState().auth.contactUsInfo, 'industries', null),
    size: _.get(getState().auth.contactUsInfo, 'size', ''),
    message: _.get(getState().auth.contactUsInfo, 'message', '')
  };
  dispatch(isLoading());
  try {
    const res = await http.post(ContactUs, data);
    if(res.message) {
      toast.error(res.message);
      dispatch({type: actionTypes.CONTACT_US_ERROR, contactError: res.message});
    } else {
      dispatch({type: actionTypes.CONTACT_US_SUCCESS, contactSuccess: res[0], contactPopUpStatus: false});
      history.push('/thankyou');
    }
  } catch(error) {
    toast.error(error.message);
    dispatch(networkError(error.message));
    window.location.reload();
  }
};

const submitPricingEnterprise = (history, payload) => async (dispatch) => {
  dispatch(isLoading());
  try {
    const res =  await http.post(PricingEnterprise, payload);
    if(res.message){
      toast.error(res.message);
      dispatch({type: actionTypes.PRICING_ENTERPRISE_ERROR, pricingError: res.message})
    } else {
      toast.success(res[0]);
      setTimeout(() => {
        dispatch({type: actionTypes.PRICING_ENTERPRISE_SUCCESS});
        history.push('/');
      }, 2000);
    }
  } catch (error) {
    toast.error(error.message);
    dispatch(networkError(error.message));
    window.location.reload();
  }
};

const subscribeNewsLetter = (history, payload) => async (dispatch) => {
  try {
    const res =  await http.post(Newsletter, payload);
    if(res.message){
      toast.error(res.message);
      dispatch({type: actionTypes.NEWSLETTER_ERROR, newsLetterError: res.message})
    } else {
      toast.success(res[0]);
      dispatch({type: actionTypes.NEWSLETTER_SUCCESS});
    }
  } catch (error) {
    toast.error(error.message);
    dispatch(networkError(error.message));
    window.location.reload();
  }
};

export { checkAndUpdateRole, authenticatedUser, login, logout, getAdminUser, networkError, saveContactUsInfo, submitContactForm, forgetPassword, resetPassword, getUserRole, submitPricingEnterprise, subscribeNewsLetter, authenticatedUserEmailToken,loginwithsso};